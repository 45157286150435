import React, { useContext, useMemo } from "react";
import { t } from "@lingui/macro";
import { navigate } from "gatsby";
import { SignUpFlowLocationType } from "../types/SignUpFlowLocationType";
import { PaymentContext } from "../contexts/PaymentProvider";

export const SubscriptionPlanOverview = ({ location }: { location: SignUpFlowLocationType | undefined }) => {
  const tt = useTranslations();

  const { isReady, selectedPlan } = useContext(PaymentContext);

  const isMonthly = selectedPlan === "MONTHLY";
  const isAnnual = selectedPlan === "ANNUAL";

  const queryParams = useMemo(() => location?.search || "", [location]);

  return (
    <div
      className={`border-blue-500 border-2 group rounded-xl p-4 overflow-hidden mt-8 w-full ${
        !isReady ? "opacity-75" : ""
      }`}
      tabIndex={0}
    >
      <div className="flex flex-row">
        <div className="w-full">
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              <h4 className="m-0 display-xxxs">{tt.freeTrial}</h4>
            </div>

            <div className="text-green rounded-full m-0 py-0.5 px-0 self-end display-xxxs">
              {tt.chargeToday}
            </div>
          </div>

          <div className="flex items-start justify-between">
            <div className="m-0 mt-1 text-grey body-s">
              {isAnnual ? tt.annualMembership : tt.monthlyMembership}
            </div>

            <div className="self-end m-0 mt-1 text-grey body-s">
              {isAnnual ? tt.annualAfterTrial : tt.monthlyAfterTrial}{" "}
            </div>
          </div>

          <hr className="border-b-1 border-grey my-4" />

          <div className="mt-1 flex justify-between items-center">
            <span className="m-0 mr-1 body-m flex items-center">
              {/* Star icon */}
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                className="mr-1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.52447 0.463526C7.67415 0.0028708 8.32585 0.00286996 8.47553 0.463525L9.90837 4.87336C9.97531 5.07937 10.1673 5.21885 10.3839 5.21885H15.0207C15.505 5.21885 15.7064 5.83865 15.3146 6.12336L11.5633 8.84878C11.3881 8.9761 11.3148 9.20179 11.3817 9.4078L12.8145 13.8176C12.9642 14.2783 12.437 14.6613 12.0451 14.3766L8.29389 11.6512C8.11865 11.5239 7.88135 11.5239 7.70611 11.6512L3.95488 14.3766C3.56303 14.6613 3.03578 14.2783 3.18546 13.8176L4.6183 9.4078C4.68524 9.20179 4.61191 8.9761 4.43667 8.84878L0.685441 6.12336C0.293584 5.83866 0.494972 5.21885 0.979333 5.21885H5.6161C5.83272 5.21885 6.02469 5.07937 6.09163 4.87336L7.52447 0.463526Z"
                  fill="#4FAE64"
                />
              </svg>
              {isAnnual ? tt.annualDiscountApplied : tt.switchToAnnual}
            </span>

            {isMonthly && (
              <button
                className={`m-0 body-m text-light-blue font-medium hover:opacity-75 ${
                  !isReady ? "opacity-75 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (isReady) {
                    navigate(`../plan${queryParams}`, {
                      replace: false,
                      state: {
                        plan: selectedPlan,
                      },
                    });
                  }
                }}
              >
                {tt.change}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function useTranslations() {
  return {
    freeTrial: t({
      id: "SignUpFlow.freeTrial",
      message: "30-day free trial",
    }),
    chargeToday: t({
      id: "SignUpFlow.chargeToday",
      message: "$0 Today",
    }),
    annualMembership: t({
      id: "SignUpFlow.annualMembership",
      message: "Annual membership",
    }),
    monthlyMembership: t({
      id: "SignUpFlow.monthlyMembership",
      message: "Monthly membership",
    }),
    annualAfterTrial: t({
      id: "SignUpFlow.annualAfterTrial",
      message: "after trial $119/year",
    }),
    monthlyAfterTrial: t({
      id: "SignUpFlow.monthlyAfterTrial",
      message: "after trial $14.99/mo",
    }),
    annualDiscountApplied: t({
      id: "SignUpFlow.annualDiscountApplied",
      message: "Annual discount applied",
    }),
    switchToAnnual: t({
      id: "SignUpFlow.switchToAnnual",
      message: "Switch to annual, save $60.88",
    }),
    change: t({
      id: "SignUpFlow.change",
      message: "Change",
    }),
  };
}
