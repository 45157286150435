import { t } from "@lingui/macro";
import React, { useCallback, useContext } from "react";
import { PaymentContext } from "../contexts/PaymentProvider";

const SubscriptionPlanSelect = ({ disabled = false }: { disabled?: boolean; className?: string }) => {
  const { selectedPlan, setSelectedPlan } = useContext(PaymentContext);

  const tt = useTranslations();

  const handleEnterAnnual = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && !disabled) {
        setSelectedPlan("ANNUAL");
      }
    },
    [disabled, setSelectedPlan]
  );

  const handleClickAnnual = useCallback(async () => {
    if (!disabled) {
      setSelectedPlan("ANNUAL");
    }
  }, [disabled, setSelectedPlan]);

  const handleEnterMonthly = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && !disabled) {
        setSelectedPlan("MONTHLY");
      }
    },
    [disabled, setSelectedPlan]
  );

  const handleClickMonthly = useCallback(async () => {
    if (!disabled) {
      setSelectedPlan("MONTHLY");
    }
  }, [disabled, setSelectedPlan]);

  const isMonthly = selectedPlan === "MONTHLY";

  const isAnnual = selectedPlan === "ANNUAL";

  return (
    <>
      <div className="flex flex-row justify-between mt-9">
        {/* Monthly v2 */}
        <div
          className={`
              ${
                isMonthly
                  ? "border-black shadow-lg"
                  : "border-lighter-grey hover:border-dark-grey bg-transparent shadow-sm"
              } p-3 py-4 w-42 md:w-52 rounded-xl flex flex-col items-center border-2 group cursor-pointer transition-colors transition-opacity duration-200 group`}
          onClick={handleClickMonthly}
          onKeyDown={handleEnterMonthly}
          tabIndex={0}
          id="_form_checkout-payment-method-plan-MONTHLY"
          data-hj-ignore-attributes={true}
        >
          <div
            className={`text-center
              ${isMonthly ? "" : "opacity-80"}`}
          >
            <h3 className="m-0 mt-1 headline-xxs md:headline-xs font-medium">{tt.monthlyTitle}</h3>
            <div className="m-0 body-xs mt-0.5">{tt.monthlyTrial(30)}</div>
            <div className="text-4xl md:text-[2.5rem] font-medium mt-1.5">{tt.monthlyPricePerMonth}</div>
            <div className="body-m m-0 -mt-1.5">{tt.perMonth}</div>
            <div className="body-xs text-grey mt-3">{tt.monthlyPerYearCalculated}</div>
          </div>

          <div
            className={`w-12 h-12 mt-4 border border-grey rounded-full flex justify-center items-center 
          ${isMonthly ? "border-black" : "group-active:border-black"}`}
          >
            <div
              className={`rounded-full w-10 h-10 transition-colors duration-200 ${
                isMonthly ? "bg-gold" : "group-hover:bg-grey group-active:bg-black bg-transparent"
              }`}
            ></div>
          </div>
        </div>

        {/* Annual v2 */}
        <div
          className={`
              ${
                isAnnual
                  ? "border-black shadow-lg"
                  : "border-lighter-grey hover:border-dark-grey bg-transparent shadow-sm"
              } p-3 py-4 w-42 md:w-52 rounded-xl flex flex-col items-center border-2 group cursor-pointer transition-colors transition-opacity duration-200 group`}
          onClick={handleClickAnnual}
          onKeyDown={handleEnterAnnual}
          tabIndex={0}
          id="_form_checkout-payment-method-plan-YEARLY"
          data-hj-ignore-attributes={true}
        >
          <div className="uppercase -mt-6 bg-green rounded-full text-white label-s py-0.5 px-3 font-medium">
            {tt.annualSaving}
          </div>
          <div
            className={`text-center
              ${isAnnual ? "" : "opacity-80"}`}
          >
            <h3 className="m-0 mt-1 headline-xxs md:headline-xs font-medium">{tt.annualTitle}</h3>
            <div className="m-0 body-xs mt-0.5">{tt.annualTrial(30)}</div>
            <div className="text-4xl md:text-[2.5rem] font-medium mt-1.5">{tt.annualPricePerMonth}</div>
            <div className="body-m m-0 -mt-1.5">{tt.perMonth}</div>
            <div className="body-xs text-grey mt-3">{tt.annualPerYearCalculated}</div>
          </div>

          <div
            className={`w-12 h-12 mt-4 border border-grey rounded-full flex justify-center items-center 
          ${isAnnual ? "border-black" : "group-active:border-black"}`}
          >
            <div
              className={`rounded-full w-10 h-10 transition-colors duration-200 ${
                isAnnual ? "bg-gold" : "group-hover:bg-grey group-active:bg-black bg-transparent"
              }`}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

function useTranslations() {
  return {
    monthlyTitle: t({
      id: "SignUpFlow.monthlyTitle",
      message: "Monthly billing",
    }),
    monthlyTrial: (duration: number) =>
      t({
        id: "SignUpFlow.monthlyTrial",
        message: `First ${duration} days free`,
      }),
    monthlyPricePerMonth: t({
      id: "SignUpFlow.monthlyPrice",
      message: `$14.99`,
    }),
    perMonth: t({
      id: "SignUpFlow.perMonth",
      message: "/month",
    }),
    monthlyPerYearCalculated: t({
      id: "SignUpFlow.monthlyPerYearCalculated",
      message: "$179.88 per year",
    }),
    annualSaving: t({
      id: "SignUpFlow.annualSaving",
      message: "SAVE $60.88",
    }),
    annualTitle: t({
      id: "SignUpFlow.annualTitle",
      message: "Annual billing",
    }),
    annualTrial: (duration: number) =>
      t({
        id: "SignUpFlow.annualTrial",
        message: `First ${duration} days free`,
      }),
    annualPricePerMonth: t({
      id: "SignUpFlow.annualPrice",
      message: "$9.92",
    }),
    annualPerYearCalculated: t({
      id: "SignUpFlow.annualPerYearCalculated",
      message: "$119 per year",
    }),
  };
}

export default React.memo(SubscriptionPlanSelect);
